/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: #ffffff00 !important;
}

.bg-color {
  background-color: var(--app-bg-color);
}

.with-border {
  border: 1px solid rgb(0, 0, 0, 0.2);
}

:root {
  --app-bg-color: rgb(0, 0, 0, 0.1);
  --app-border-color: rgb(0, 0, 0, 0.2);
}